import React from "react";
import './StoneOption.css'
import { print_stone } from "../../Pages/CustomStone";

const StoneOption = (props) => {
    const {stone} = props
    if(stone!==null){
        return (
            <div id={'div-'+String(stone.id)} className="div-stone">
                <button onClick={()=>print_stone(stone)} id={'btn-'+String(stone.id)} className="btn-stone">
                   <img src={'data:image/jpg;base64,'+stone.image} id={'img-'+String(stone.id)} alt="" className="img-stone"/> 
                <span className="stone-info"><p>{stone.name}</p> ${stone.price}</span>
                </button>
            </div>
        )
    }
    else{
        return (<></>)
    }
}

export default StoneOption