import React from "react";
import { add_to_cart } from "./Cart";
import { Link } from "react-router-dom";
import './CSS/CustomBezel.css'
import {useState, useEffect} from "react";

export let selected_bezel = null;

const select_bezel = (bezel_type, bezel_prices) =>{
    var high = document.getElementById("high-bezel")
    var low = document.getElementById("low-bezel")
    if(bezel_type===0){
        selected_bezel = {"name": "High Bezel","image": bezel_prices[0].high_bezel_img,"price": bezel_prices[0].high_bezel_price } 
        high.style.backgroundColor = "darkcyan"
        low.style.backgroundColor = "white"
    }
    else{
        selected_bezel = {"name": "Low Bezel","image": bezel_prices[0].low_bezel_img,"price": bezel_prices[0].low_bezel_price } 
        high.style.backgroundColor = "white"
        low.style.backgroundColor = "darkcyan"
    }

}

const CustomBezel = () =>{
    const [bezel_prices, setBezelPrices] = useState(null)
    useEffect(()=> {
        fetch(process.env.REACT_APP_API_URL+'/bezel_prices')
            .then(response => {
                response.json().then((data) => {
                    if(bezel_prices === null)
                        setBezelPrices(data)
                })
            })
            .catch(error=>{
                console.error(error);
            })
    }, [bezel_prices]);
    if(bezel_prices===null)return<p>There are no bezels available at the moment. Please check back soon!</p>
    return(
        <div>
            <div className="bezel">
                <div className="high-bezel">
                    <button onClick={()=>select_bezel(0,bezel_prices)} id="high-bezel">
                        <img src={'data:image/JPG;base64,'+bezel_prices[0].high_bezel_img} alt=""/> 
                    </button>
                    <p>High Bezel</p>
                    <p>${bezel_prices[0].high_bezel_price}</p>
                </div>
                <div className="low-bezel">
                    <button onClick={()=>select_bezel(1,bezel_prices)} id="low-bezel">
                        <img src={'data:image/JPG;base64,'+bezel_prices[0].low_bezel_img} alt=""/> 
                    </button>
                    <p>Low Bezel</p>
                    <p>${bezel_prices[0].low_bezel_price}</p>
                </div> 
            </div>
            <div className="continue">
                <Link to="/cart">
                    <button onClick={()=>add_to_cart(selected_bezel)} className="continue-btn-checkout">Checkout</button>                
                </Link>
            </div>
        </div>
    )
}

export default CustomBezel