import React, { useEffect, useState } from "react";
import './CSS/CustomBand.css'
import BandOption from "../Components/BandOption/BandOption";
import { Link } from "react-router-dom";
import { add_to_cart } from "./Cart";

export let selected_band = null;
export const print_band = (band) => {
    if(band===null)return
    if(band.image!== null){
        var container = document.getElementById('band')
        if(container!== null){
            var image = new Image();
            var p = document.createElement('p')
            var price = document.createElement('p')
            image.src = 'data:image/webp;base64,' + String(band.image)
            image.className = 'img-band-selected'
            image.id = 'img-'+String(band.name)+String(band.id)
            p.innerHTML = band.name
            price.innerHTML = "$ " + band.price
            container.innerHTML = p.outerHTML + image.outerHTML + price.outerHTML
            selected_band = band
        }
    }
}

const CustomBand = () => {
    const [bands, setBands] = useState(null)
    useEffect(()=> {
        fetch(process.env.REACT_APP_API_URL+'/all_bands?')
            .then(response => {
                response.json().then((data) => {
                    if(bands === null)
                        setBands(data)
                })
            })
            .catch(error=>{
                console.error(error);
            })
    }, [bands]);
    if(bands===null)return<p>There are no bands available at the moment. Please check back soon!</p>
    return(
        <div className="custom-bands" id='custom-bands'>
            Select Your Band:
            <div className="band" id="band"></div>
            <div id='band_choice' className="band-choice">
               {bands.map((band,i)=>{
                   band = JSON.parse(band)
                    if( document.getElementById('bo'+String(band.id))===null){
                        return(<BandOption id={'bo'+String(band.id)} band={band} key={band.id}/> )
                    }
                    else{
                        return <></>
                    }
               })

               } 
            </div>
            <div className="continue">
                <Link to="/custom_bezel">
                    <button onClick={()=>add_to_cart(selected_band)} className="continue-btn">Select Band</button>                
                </Link>
            </div>
        </div>
    )
}

export default CustomBand