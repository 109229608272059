import React from "react";
import './RelatedProducts.css'
import Item from "../Item/Item";
import { useState, useEffect } from "react";

const RelatedProducts = (props) => {
    const [all_product, setRings] = useState(null)
    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'/all_rings')
            .then(response => {
                response.json().then((data) => {
                    if(all_product === null)
                        setRings(JSON.parse('['+data+']'))
                    })
            })
            .catch(error=> {
                console.error(error);
            })
    }, [all_product]);
    const {selectedItem} = props;
    if(all_product===null)return<></>
    else{
        return (
            <div className="relatedproducts">
                <h1>Related Products</h1>
                <hr/>
                <div className="relatedproducts-item">
                    {all_product.map((item, i)=>{
                        if( item.id!== selectedItem.id && selectedItem.category === item.category){ 
                            return <Item key={i} id={item.id} name={item.name} image={item.image} price={item.price}/>
                        }
                        else{
                            return null;
                        }
                    })}      
                </div>
            </div>
        )
    }
}

export default RelatedProducts