import React from 'react'
import './Item.css'
import { Link } from 'react-router-dom'

const Item = (props) => {
    return (
        <div className='item'>
            <Link to={'/product/'+ String(props.id)}><img className='item-img' src={'data:image/JPG;base64,'+props.image} alt="" /> </Link>
            <p>{props.name}</p>
            <div className='item-prices'>
                <div className='item-price'>
                    ${props.price}
                </div>
            </div>
        </div>
    )
}

export default Item