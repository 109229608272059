import React from 'react'
import arrow_icon from '../Assets/breadcrum_arrow.png'
import './Breadcrumb.css'
import { Link } from 'react-router-dom'

const Breadcrum = (props) => {
    const {product} = props;

    return (
        <div className='breadcrumb'>
           <Link to='/'>HOME</Link> <img src={arrow_icon} alt="" /><Link to='/shop'> SHOP</Link> <img src={arrow_icon} alt='' />{product.category} <img src={arrow_icon} alt='' />            
        </div>
    )
}

export default Breadcrum