import React, { useState } from 'react'
import './CSS/Login.css'
import sjcl from 'sjcl'
import { useNavigate } from 'react-router-dom';

export let logged_in = false;

const Login=() => {
    const navigate = useNavigate();
    const [api_data,SetAPIData] = useState();
    
    const handleRedirect = (page) => {
        logged_in = true
        navigate(page)
    }
    const encode_info = (str) => {
        const bitArray = sjcl.hash.sha256.hash(str);
        const myHash = sjcl.codec.hex.fromBits(bitArray)
        return myHash;
    }
    const login_func = async () => {
        let username = "";
        let pw = "";
        let username_element = document.getElementById('username')
        let pw_element = document.getElementById('pw')
        let cnt = true;
        if(username_element === null){
            window.alert("Incorrect Login Info")    
            cnt = false;
        }
        if(pw_element === null){
            window.alert("Incorrect Login Info")    
            cnt = false;
        }
        if(cnt){
            username = encode_info(username_element.value); 
            pw = encode_info(pw_element.value);
	    console.log(process.env.REACT_APP_API_URL); 
           const response = await fetch(process.env.REACT_APP_API_URL+"/login",{
                method:["POST"],
                headers: {"Content-Type":"application/json"},
                body: JSON.stringify({user: username, password: pw}),
            })
            const data = await response.text()
            if (data ==='username'){
                window.alert("Incorrect Username")
            }
            else if(data === "password" ){
                window.alert("Incorrect Password")
            }
            else if(data ==='/admin_management'){
                handleRedirect(data)
            }
            else{
                window.alert(data)
            }
        }
    }
    return(
        <div>
            <div className='loginsignup'>
                <div className="loginsignup-container">
                    <h1>Admin Login Only</h1>
                    <div className="loginsignup-fields">
                        <input id='username' type="text" placeholder = 'Username' />
                        <input id='pw' type="password" placeholder="Password" />
                    </div>
                    <button className="login-btn" onClick={()=> login_func()}>Continue</button>
                    <div className="loginsignup-agree">
                        <p>This page is for administrative access only.</p>
                    </div>
                </div>
            </div>
        
        </div>
    )
}

export default Login
