import React, { useState,useEffect } from "react";
import './CSS/CustomStone.css'
import StoneOption from "../Components/StoneOption/StoneOption";
import { Link } from "react-router-dom";
import { add_to_cart } from "./Cart";

export let selected_stone = null;

export const print_stone = (stone) => {
    if(stone===null)return 
    if(stone.image !==  null){
        var container = document.getElementById('stone')
        if(container !== null){
            var image = new Image();
            var p = document.createElement('p')
            var price = document.createElement('p')
            image.src = 'data:image/jpg;base64,' + String(stone.image)
            image.className = 'img-stone-selected'
            image.id = 'img-'+String(stone.name)+String(stone.id)
            p.innerHTML = stone.name
            price.innerHTML = "$ " + stone.price
            container.innerHTML = p.outerHTML + image.outerHTML + price.outerHTML
            selected_stone = stone
        }
    }
}

const CustomStone = (props) => {
    const [stones, setStones] = useState(null)
    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'/all_stones?')
            .then(response => {
                response.json().then((data) => {
                    if(stones === null)
                        setStones(data)
                    })
            })
            .catch(error=> {
                console.error(error);
            })
    }, [stones]);
    if(stones===null)return<p>There are no stones available at the moment. Please check back soon!</p>
    return (
        <div id='custom-page' className="custom-page">
            Select Your Stone:
            <div className="stone" id="stone"></div>
            <div id="stone_choice" className="stone-choice">
                {stones.map((stone,i)=>{
                    stone = JSON.parse(stone)
                    if( document.getElementById('so'+String(stone.id))===null){
                    return(
                            <StoneOption id={'so'+String(stone.id)} stone={stone} key={stone.id}/>
                    )}
                    else{
                        return <></>
                    }
                })}
            </div>
            <div className="continue">
                <Link to="/custom_band">
                    <button onClick={()=>add_to_cart(selected_stone)} className="continue-btn">Select Band</button>                
                </Link>
            </div>
        </div>
    )
}

export default CustomStone