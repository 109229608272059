import React, { useState, useEffect } from "react";
import './CSS/ShopCategory.css'
import Item from "../Components/Item/Item";

var items_shown = 0;
var first_item = 0;
const ShopCategory = () => {
    const [all_product, setProducts] = useState(null);
    const [ring_size, setSize] = useState('');
    const [stone_type,setStoneType] = useState('');
    const setSelectedSize = (size) => {
            first_item = 0
            items_shown = 0
            setSize(size);
    }
    const setSelectedStone = (stone) =>{
            first_item = 0
            items_shown = 0
            setStoneType(stone)
    }
    var num_total_items = 0
    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'/all_rings')
            .then(response => {
                response.json().then((data) => {
                    if(all_product === null)
                        setProducts(data)
                    })
            })
            .catch(error=> {
                console.error(error);
            })
    }, [all_product]);
    const should_show = (item) => {
        if(ring_size !== '' && ring_size !== item.size){
            return false
        }
        if(stone_type !== '' && stone_type !== item.category){
            return false
        }
        if(items_shown<num_total_items){
            items_shown++;
        }
        first_item = 1;
        return true;
    }
    if(all_product!==null){
        num_total_items = all_product.length
    }
    else{
        return <p>There are no items for sale yet, check back soon!</p>
    }
    return (
        <div className="shop-category">
            <div className="shopcategory-indexSort">
                <p>
                    <span>Showing {first_item}-{items_shown}</span> out of {num_total_items} products
                </p>
                <div>
                    <label className="ring_size_label">Ring Size</label>
                    <br/>
                    <select onChange={() => setSelectedSize(document.getElementById('ring_size').value)} id='ring_size' className='size' name='size'>
                        <option value=''>All</option>
                        <option value='5'>5</option> 
                        <option value='5.5'>5.5</option> 
                        <option value='6'>6</option> 
                        <option value='6.5'>6.5</option> 
                        <option value='7'>7</option> 
                        <option value='7.5'>7.5</option> 
                        <option value='8'>8</option> 
                        <option value='8.5'>8.5</option> 
                        <option value='9'>9</option> 
                        <option value='9.5'>9.5</option> 
                        <option value='10'>10</option> 
                    </select>
                </div>
                <div>
                    <label className="stone_type_label">Stone Type</label>
                    <br/>
                    <select onChange={() => setSelectedStone(document.getElementById('ring_category').value)}id='ring_category' className='category-input' name='category'>
                        <option value="">All</option>
                        <option value="Bisbee">Bisbee</option>
                        <option value="Golden Hill">Golden Hill</option>
                        <option value="Kingman">Kingman</option>
                        <option value="Lapis">Lapis</option>
                        <option value="Royston">Royston</option>
                        <option value="Sleeping Beauty">Sleeping Beauty</option>
                        <option value="Spiny">Spiny Oyster</option>
                        <option value="Turquoise">Turquoise</option>
                        <option value="White Buffalo">White Buffalo</option>
                    </select>
                </div>
            </div>
            <div className="shopcategory-grid">
                {all_product.map((item,i)=>{
                    item = JSON.parse(item)
                    if(should_show(item)){
                        return <Item className="shopcategory-products" key={i} id={item.id} name={item.name} image={item.image} price={item.price}/>
                    }
                    else{
                        return null;
                    }
                })}
            </div>
        </div>
    )
}

export default ShopCategory