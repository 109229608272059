import React from 'react'
import './NewsLetter.css'


const NewsLetter = () => {
    const sendEmail = async () => {
        let email = document.getElementById('email_input').value
        if( email.search(';') !== -1 ){
            window.alert("Semi colons are not allowed");
            return;
        }
        if( email.search('@') === -1 ){
            window.alert("Invalid email input");
            return;
        }
        const response = await fetch(process.env.REACT_APP_API_URL+"/newsletter_signup",{
            method:["POST"],
            headers: {"Content-Type":"application/json"},
            body: JSON.stringify({email: email}),
        })
        const data = await response.text()
        if(data !== 'ok'){
            window.alert(data)
        }
        document.getElementById('email_input').value = ''
    }
    return (
        <div className="newsletter">
            <h1>Get Exclusive Offers On Your Email</h1>
            <p>Subscribe to our newsletter and stay updated</p>
            <div>
                <input id="email_input" type="email" placeholder='Your Email Id' />
                <button onClick={()=>sendEmail()}>Subscribe</button>
            </div>
        </div>
    )
}

export default NewsLetter