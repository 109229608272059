import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Product from './Pages/Product';
import Cart  from './Pages/Cart';
import CustomStone from './Pages/CustomStone';
import Login from './Pages/Login';
import Home from './Pages/Home';
import Footer from './Components/Footer/Footer';
import ShopCategory from './Pages/ShopCategory';
import Checkout from './Pages/Checkout'
import CompletePage from './Pages/CompletePage';
import About from './Pages/About';
import Contact from './Pages/Contact';
import CustomBand from './Pages/CustomBand';
import CustomBezel from './Pages/CustomBezel';
import AdminManagement from './Pages/AdminManagement';
import ManageBands from './Pages/ManageBands';
import ManageStones from './Pages/ManageStones';
import ManageRings from './Pages/ManageRings';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useState,useEffect } from 'react';

const stripePromise = loadStripe("pk_test_51PuRxsRpCROWShhljWDaUB40OGmL9GDi7kzG9LU1PhuQIPcOG2tVHNvfN0xL9CF0hqLxzvn0eY7BUkbnCGACEqpr00Y5JhAHdG")


function App() {
  const [clientSecret, setClientSecret] = useState("");
  const [dpmCheckerLink, setDpmCheckerLink] = useState("");
  useEffect(()=>{
      fetch(process.env.REACT_APP_API_URL+"/create-payment-intent",{
        method:"POST",
        headers: {"Content-Type":"application/json"},
        body: JSON.stringify({price: 50000}),
      })
      .then((res)=>res.json())
      .then((data)=>{
        setClientSecret(data.clientSecret);
        setDpmCheckerLink(data.dpmCheckerLink);
      })
  },[])
  const options = {
    clientSecret
  }
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path='/' element = {<Home/>}/>
        <Route path='/shop' element = {<ShopCategory />}/>
        <Route path='/custom' element={<CustomStone/>}/>
        <Route path='/custom_band' element={<CustomBand/>}/>
        <Route path='/custom_bezel' element={<CustomBezel/>}/>
        <Route path='/product' element={<Product />}>
          <Route path=':productId' element={<Product />}/>
        </Route>
        <Route path='/cart' element={<Cart />}/>
        <Route path="/admin" element={<Login/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/admin_management" element={<AdminManagement />}/>
        <Route path="/admin_manage_bands" element={<ManageBands/>}/>
        <Route path="/admin_manage_rings" element={<ManageRings/>}/>
        <Route path="/admin_manage_stones" element={<ManageStones/>}/>
      </Routes>
      {clientSecret && (
      <Elements options={options} stripe={stripePromise} className='stripe_elements'>
        <Routes>
          <Route path="/checkout" element={<Checkout dpmCheckerLink={dpmCheckerLink} />}/>
          <Route path="/complete" element={<CompletePage/>}/>
        </Routes>
      </Elements>
      )}
      <Footer/>
      </BrowserRouter>
    </div>
  );
}
export default App;
