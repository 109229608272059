import React from 'react'
import artist_img from '../Components/Assets/home_photo1.jpeg'
import './CSS/Home.css'
import NewsLetter from '../Components/NewsLetter/NewsLetter'
const Home=() => {
    return(
        <div className='artist'>
            <h1>About Us:</h1>
            <img className='home-photo' src={artist_img} alt="" />
            <div className='about'>
                <p>Every piece is handmade by the artist Kylie Roberts (shown right) and website was made by Brandon Todd (shown left)</p>
            </div>
            <NewsLetter/>
        </div>
    )
}

export default Home 