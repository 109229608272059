import React from 'react'
import './ProductDisplay.css'
import { add_to_cart } from '../../Pages/Cart'

const ProductDisplay = (props) => {
    const {product} = props;

    return (
        <div className='productdisplay'>
            <div className="productdisplay-left">
                <div className='productdisplay-img'>
                    <img className="productdisplay-main-img" src={'data:image/JPG;base64,'+product.image} alt="" />
                </div>
            </div>
            <div className="productdisplay-right">
                <h1>{product.name}</h1>
                <div className="productdisplay-right-prices">
                    <div className="productdisplay-right-price">
                        ${product.price}
                    </div>
                </div>
                <div className="productdisplay-right-carrats">
                    Description: {product.description}
                </div>
                <div className="productdisplay-right-size">
                    Ring Size: {product.size}
                </div>
                <p className='productdisplay-right-category'><span>Stone: </span>{product.category}</p>
                <div className='add-to-cart-container'>
                    <button onClick={()=>add_to_cart(product)}>ADD TO CART</button>
                </div>
            </div>
        </div>
    )
}

export default ProductDisplay