import React from 'react'
import './Footer.css'
import footer_logo from '../Assets/logo_small.jpg'
import instagram_icon from '../Assets/instagram_icon.png'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-logo'>
                <img src={footer_logo} alt="" />
                <p>Boo Jewelry</p>
            </div>
            <ul className='footer-links'>
                <Link to="/shop"><li>Shop</li></Link>
                <Link to='/about'><li>About</li></Link>
                <Link to='/contact'><li>Contact</li></Link>
            </ul>
            <div className='footer-social-icons'>
                <Link to='https://www.instagram.com/' className = "footer-icons-container">
                    <img src={instagram_icon} alt="" />
                </Link>
            </div>
            <div className= "footer-copyright">
            </div>
        </div>
    )
}

export default Footer