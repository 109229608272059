import React, { useState } from 'react'
import './Navbar.css'
import cart_icon from '../Assets/cart_icon.png'
import logo_small from '../Assets/logo_small.jpg'
import { Link } from 'react-router-dom'

const Navbar = () => {
    const [menu, setMenu] = useState("home")
    return (
        <div className='navbar'>
            <div className='nav-logo'>
                <Link onClick={()=>setMenu("home")} style = {{ textDecoration:'none'}} to='/' className='link'>
                    <img src={logo_small} alt="" />
                </Link>{menu==="home"?<hr/>:<></>}
                <p>Boo!</p>
            </div>
            <ul className='nav-menu'>
                <li onClick={()=>setMenu("shop")}><Link style={{ textDecoration:'none'}} to='/shop' className='link'>Shop</Link>{menu==="shop"?<hr/>:<></>}</li>
                <li onClick={()=>setMenu("custom")}><Link style={{ textDecoration:'none'}} to='/custom' className='link'>Custom Work</Link>{menu==="custom"?<hr/>:<></>}</li>
            </ul>
            <div className='nav-login-cart'>
                <Link to='/cart/'><img src={cart_icon} alt="" />
                <span id='nav-cart-count' className='nav-cart-count'>0</span> 
                </Link>
            </div>
        </div>
    )
}

export default Navbar