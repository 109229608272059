import React, {useState,useEffect} from 'react'
import { logged_in } from './Login.jsx'
import { Link } from 'react-router-dom'
import './CSS/ManageRings.css'
import Item from '../Components/Item/Item.jsx';
import edit_icon from '../Components/Assets/edit_pencil_icon.jpg'

export const get_max_id = (items) =>{
    let id = 0
    if(items===null)return 0
    for( let i of items){
        i = JSON.parse(i)
        if(i.id >id){
            id = i.id
        }
    }
    return id
}
const ManageRings =() => {
    const [rings, setRings] = useState(null)
    const [text, setText] = useState(null)
    const [ring, setRing] = useState(null)
    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'/all_rings')
            .then(response => {
                response.json().then((data) => {
                    if(rings === null)
                        setRings(data)
                    })
            })
            .catch(error=> {
                console.error(error);
            })
    }, [rings]);
    const get_file_text = async (event) => {
        let file = document.getElementById('ring_img').files[0]
        event.preventDefault()
        if(file){
            var reader = new FileReader();
            reader.onload = async (event) => {
                setText(event.target.result.split(',')[1]);
            }
            reader.onerror = function(event){
                window.alert("Error Reading File")
            }
            reader.readAsDataURL(file)
            }
    }
    let max_id = get_max_id(rings) 
    const add_ring = async (max_id, text) => {
        let ring_img = null
        ring_img =  text;
        let ring_name = document.getElementById('ring_name').value
        if(ring_name===null){
            window.alert('Invalid input to ring name');
            return
        }
        let ring_description = document.getElementById('ring_description').value
        if(ring_description===null){
            window.alert('Invalid input to ring description');
            return
        }
        let ring_price = document.getElementById('ring_price').value
        if(ring_price===null){
            window.alert('Invalid input to ring price');
            return
        }
        let ring_size = document.getElementById('ring_size').value
        if(ring_size===null){
            window.alert('Invalid input to ring size');
            return
        }
        let ring_category = document.getElementById('ring_category').value
        if(ring_category===null){
            window.alert('Invalid input to ring category');
            return
        }
        if(ring_img===null){
            window.alert('Invalid input to ring image file');
            return
        }
        let cost_input = document.getElementById('ring_cost').value
        if(cost_input === null){
            window.alert('Invalid input to ring cost')
        }
        const response = await fetch(process.env.REACT_APP_API_URL+"/insert_ring",{
            method:["POST"],
            headers: {"Content-Type":"application/json"},
            body: JSON.stringify({
                name: ring_name,
                description: ring_description,
                price: ring_price,
                size: ring_size,
                image: ring_img,
                id: max_id+1,
                category: ring_category,
                cost: cost_input
            }),
        })
        const data = await response.text()
        if(data !== 'ok'){
            window.alert(data)
        }
        setRings(null)
        return
    }
    const delete_ring = async (ring_id) =>{
        if(window.confirm("Are you sure you want to delete this ring?")){
            const response = await fetch(process.env.REACT_APP_API_URL+"/delete_ring",{
                method:["POST"],
                headers: {"Content-Type":"application/json"},
                body: JSON.stringify({
                    id: ring_id
                }),
            })
            const data = await response.text()
            if(data !== 'ok'){
                window.alert(data)
            }
            setRings(null)
        }
    }
    const save_edits = async () =>{
        let add_ring_btn = document.getElementById('add-ring-btn') 
        let edit_ring_btn = document.getElementById('edit-ring-btn')
        let ring_name = document.getElementById('ring_name').value
        if(ring_name===null){
            window.alert('Invalid input to ring name');
            return
        }
        let ring_description = document.getElementById('ring_description').value
        if(ring_description===null){
            window.alert('Invalid input to ring description');
            return
        }
        let ring_price = document.getElementById('ring_price').value
        if(ring_price===null){
            window.alert('Invalid input to ring price');
            return
        }
        let ring_size = document.getElementById('ring_size').value
        if(ring_size===null){
            window.alert('Invalid input to ring size');
            return
        }
        let ring_category = document.getElementById('ring_category').value
        if(ring_category===null){
            window.alert('Invalid input to ring category');
            return
        }
        let cost_input = document.getElementById('ring_cost').value
        if(cost_input === null){
            window.alert('Invalid input to ring cost')
            return
        }
        const response = await fetch(process.env.REACT_APP_API_URL+"/edit_ring",{
            method:["POST"],
            headers: {"Content-Type":"application/json"},
            body: JSON.stringify({
                name: ring_name,
                description: ring_description,
                price: ring_price,
                size: ring_size,
                id: ring.id,
                category: ring_category,
                cost: cost_input
            }),
        })
        const data = await response.text()
        if(data !== 'ok'){
            window.alert(data)
        }
        add_ring_btn.style.visibility = 'visible'
        edit_ring_btn.style.visibility = 'collapse'
        setRings(null)
        setRing(null)
        return
    }
    const edit_ring = (this_ring) =>{
        let add_ring_btn = document.getElementById('add-ring-btn') 
        let edit_ring_btn = document.getElementById('edit-ring-btn')
        let name_input = document.getElementById('ring_name')
        let price_input = document.getElementById('ring_price')
        let size_input = document.getElementById('ring_size')
        let desc_input = document.getElementById('ring_description')
        let category_input = document.getElementById('ring_category')
        let cost_input = document.getElementById('ring_cost')
        add_ring_btn.style.visibility = 'collapse'
        edit_ring_btn.style.visibility = 'visible'
        setRing(this_ring)
        name_input.placeholder = this_ring.name
        price_input.placeholder = this_ring.price
        size_input.placeholder = this_ring.size
        desc_input.placeholder = this_ring.description
        category_input.value = this_ring.category
        cost_input.placeholder = this_ring.cost
    }
    if(logged_in === false){
        return(
            <div>
                <p>You need to login first! This page is for admin use only!</p>
            </div>
        )
    }
    else if(rings==null) return<></>
    else{
        return(
            <div>
                <Link className='overview-link' to='/admin_management'>Admin Overview Page</Link>
                <div className='ring-input1'>
                    <input className='name-input' type='text' id='ring_name' maxLength={255} placeholder='Ring Name'/>
                    <input className='description-input' type='text' id='ring_description' maxLength={255} placeholder='Description'/>
                    <input className='price-input' type='text' placeholder='Price' id='ring_price' maxLength={255}/>
                    <input className='size-input' type='text' placeholder='Size' id='ring_size' maxLength={5}/>
                    <input className='cost-input' type='text' placeholder='Total Cost' id = 'ring_cost' maxLength={7}/>
                    <div>
                        <label>Ring Category</label>
                        <select id='ring_category' className='category-input' name='category'>
                            <option value="Bisbee">Bisbee</option>
                            <option value="Golden Hill">Golden Hill</option>
                            <option value="Kingman">Kingman</option>
                            <option value="Lapis">Lapis</option>
                            <option value="Royston">Royston</option>
                            <option value="Sleeping Beauty">Sleeping Beauty</option>
                            <option value="Spiny">Spiny Oyster</option>
                            <option value="Turquoise">Turquoise</option>
                            <option value="White Buffalo">White Buffalo</option>
                        </select>
                    </div>
                    <input className='image-input' type='file' placeholder='Image (jpg)' id='ring_img' accept="image/jpeg" onChange={(event) => get_file_text(event)}/>
                </div>
                <div className='btns'>
                    <button id='add-ring-btn' onClick={()=>add_ring(max_id, text)} className='add-ring-btn'>Add Ring</button>
                    <button id='edit-ring-btn' onClick={()=>save_edits()}className='edit-ring-btn'>Save Edits</button>
                </div>
                <div className="shopcategory-grid">
                    {rings.map((item,i)=>{
                        item = JSON.parse(item)
                        return( 
                                <div className='item' key={item.id}>
                                    <div className='ring-item'>
                                        <Item className="shopcategory-products" key={i} id={item.id} name={item.name} image={item.image} price={item.price}/>
                                    </div>
                                    <div className='delete-ring'>
                                        <button className='delete-btn' onClick={()=>delete_ring(item.id)}>
                                            X
                                        </button>
                                    </div>
                                    <div className = 'edit-ring'>
                                        <button className='edit-btn' onClick={() =>edit_ring(item)}>
                                            <img className='edit-icon' src={edit_icon} alt=''/>
                                        </button>
                                    </div>
                                </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default ManageRings 