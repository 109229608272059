import React, {useState} from 'react'
import { get_items_in_cart, get_total_price } from './Cart'
import { PaymentElement, useStripe, useElements, AddressElement, } from '@stripe/react-stripe-js'
import './CSS/Checkout.css'


const Checkout=(props) => {
    let dpmCheckerLink = {props}
    const stripe = useStripe();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    let price = get_total_price();
    const elements = useElements();
    const handleSubmit = async(e) =>{
        const response = await fetch(process.env.REACT_APP_API_URL+"/create-checkout-session",{
            method:["POST"],
            headers: {"Content-Type":"application/json"},
            body: JSON.stringify({price: price, items: get_items_in_cart()}),
        })
        const data = await response.text()
        if(data !== 'ok'){
            window.alert(data)
        }
        e.preventDefault();
        if(!stripe || !elements){
            return<></>
        }
        setIsLoading(true);
        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: "/complete",
            }
        });
            
        if(error.type==="card_error" || error.type === "validation_error"){
            setMessage(error.message);
        }
        else{
            setMessage("An unexpected error occured.");
        }
        setIsLoading(false);
    };
    const paymentElementOptions = {
        layout: "tabs",
    }
    if(1){
        return(
            <div>
                <p>This page is under maintenance. Check back soon!</p>
            </div>
        )
    }
    return(
        <div>
            <div className='price'>
               Your total price is: ${price} 
            </div>
            <div className='payment'>
            <form className='stripe-form' id='payment-form' onSubmit={handleSubmit}>
                <AddressElement  
                    options = {{ 
                        mode: 'shipping',
                        allowedCountries: ['US'],
                        blockPoBox: true,
                        fields: {
                            email: 'always',
                        },
                        validation: {
                            email: {
                                required: 'never',
                        },}}} />
                <PaymentElement id='payment-element' options={paymentElementOptions}/>
                <button className='stripe-checkout-btn'onClick={()=>handleSubmit()} disabled={isLoading || !stripe || !elements} id="submit">
                    <span id='button-text'>
                        {isLoading? <div className="spinner" id="spinner"></div>: "Pay now"}
                    </span>
                </button>
                {message && <div id="payment-message">{message}</div>}
            </form>
            </div>
            <div id="dpm-annotation" className='info'>
                <p>
                    Payment methods are dynamically displayed based on customer location, order amount, and currency.&nbsp;
                    <a className='stripe-link'href={dpmCheckerLink} target="_blank" rel="noopener noreferrer" id="dpm-integrations-checker">Preview payment methods by transaction</a>
                </p>
            </div>
        </div>
    )
}

export default Checkout 