import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Breadcrum from '../Components/Breadcrumbs/Breadcrumb';
import ProductDisplay from '../Components/ProductDisplay/ProductDisplay';
import RelatedProducts from '../Components/RelatedProducts/RelatedProducts';


const Product=() => {
    const [all_product, setRings] = useState(null)
    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'/all_rings')
            .then(response => {
                response.json().then((data) => {
                    if(all_product === null)
                        setRings(JSON.parse('['+data+']'))
                    })
            })
            .catch(error=> {
                console.error(error);
            })
    }, [all_product]);
    const get_prod_from_id = (id)=>{
        if(all_product===null)return null;
        else{
            for(let item of all_product){
                if(item.id===id){
                    return item;
                }
            }
        }
    }
    const {productId} = useParams();
    if(all_product===null){
        return <></>
    }
    else{
        const product = get_prod_from_id(Number(productId))
        if(product===null)return<></>
        else{
            return(
                <div>
                    <Breadcrum product={product}/>
                    <ProductDisplay product={product}/>
                    <RelatedProducts selectedItem={product}/>
                </div>
            )
        }
    }
}

export default Product 