import React from 'react'
import { logged_in } from './Login.jsx'
import { Link } from 'react-router-dom'
import './CSS/ManageStones.css'
import { useState, useEffect } from 'react'
import StoneOption from '../Components/StoneOption/StoneOption.jsx'
import edit_icon from '../Components/Assets/edit_pencil_icon.jpg'

export const get_max_id = (items) =>{
    let id = 100000
    if(items===null)return 100000
    for( let i of items){
        i = JSON.parse(i)
        if(i.id >id){
            id = i.id
        }
    }
    return id
}

const ManageStones =() => {
    const [stones, setStones] = useState(null)
    const [text, setText] = useState(null)
    const [stone, setStone] = useState(null)
    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'/all_stones?')
            .then(response => {
                response.json().then((data) => {
                    if(stones === null)
                        setStones(data)
                    })
            })
            .catch(error=> {
                console.error(error);
            })
    }, [stones]);
    const get_file_text = async (event) => {
        let file = document.getElementById('stone_image').files[0]
        event.preventDefault()
        if(file){
            var reader = new FileReader();
            reader.onload = async (event) => {
                setText(event.target.result.split(',')[1]);
            }
            reader.onerror = function(event){
                window.alert("Error Reading File")
            }
            reader.readAsDataURL(file)
            }
    }
    let max_id = get_max_id(stones)
    const add_stone = async (max_id, text) => {
        let stone_img = null
        stone_img =  text;
        let stone_name = document.getElementById('stone_name').value
        if(stone_name===null){
            window.alert('Invalid input to stone name');
            return
        }
        let stone_price = document.getElementById('stone_price').value
        if(stone_price===null){
            window.alert('Invalid input to stone price');
            return
        }
        if(stone_img===null){
            window.alert('Invalid input to stone image file');
            return
        }
        let cost_input = document.getElementById('stone_cost').value
        if(cost_input===null){
            window.alert('Invalid input to stone cost');
            return
        }
        let carrats_input = document.getElementById('stone_carrats').value
        if(carrats_input===null){
            window.alert('Invalid input to stone carrats');
            return
        }
        const response = await fetch(process.env.REACT_APP_API_URL+"/insert_stone",{
            method:["POST"],
            headers: {"Content-Type":"application/json"},
            body: JSON.stringify({
                name: stone_name,
                price: stone_price,
                image: stone_img,
                id: max_id+1,
                cost:cost_input,
                carrats:carrats_input
            }),
        })
        const data = await response.text()
        if(data !== 'ok'){
            window.alert(data)
        }
        setStones(null)
        return
    }
    const delete_stone = async (stone_id) =>{
        const response = await fetch(process.env.REACT_APP_API_URL+"/delete_stone",{
            method:["POST"],
            headers: {"Content-Type":"application/json"},
            body: JSON.stringify({
                id: stone_id,
            }),
        })
        const data = await response.text()
        if(data !== 'ok'){
            window.alert(data)
        }
        setStones(null)
    }
    const save_edits = async () =>{
        let add_stone_btn = document.getElementById('add-stone') 
        let edit_stone_btn = document.getElementById('edit-stone')
        let stone_name = document.getElementById('stone_name').value
        if(stone_name===null){
            window.alert('Invalid input to stone name');
            return
        }
        let stone_price = document.getElementById('stone_price').value
        if(stone_price===null){
            window.alert('Invalid input to stone price');
            return
        }
        let cost_input = document.getElementById('stone_cost').value
        if(cost_input===null){
            window.alert('Invalid input to stone cost');
            return
        }
        let carrats_input = document.getElementById('stone_carrats').value
        if(carrats_input===null){
            window.alert('Invalid input to stone carrats');
            return
        }
        const response = await fetch(process.env.REACT_APP_API_URL+"/edit_stone",{
            method:["POST"],
            headers: {"Content-Type":"application/json"},
            body: JSON.stringify({
                id: stone.id,
                name: stone_name,
                price: stone_price,
                cost:cost_input,
                carrats: carrats_input
            }),
        })
        const data = await response.text()
        if(data !== 'ok'){
            window.alert(data)
        }
        add_stone_btn.style.visibility = 'visible'
        edit_stone_btn.style.visibility = 'collapse'
        setStones(null)
        setStone(null)
        return
    }
    const edit_stone = (this_stone) =>{
        let add_stone_btn = document.getElementById('add-stone') 
        let edit_stone_btn = document.getElementById('edit-stone')
        let name_input = document.getElementById('stone_name')
        let price_input = document.getElementById('stone_price')
        let cost_input = document.getElementById('stone_cost')
        let carrats_input = document.getElementById('stone_carrats')
        add_stone_btn.style.visibility = 'collapse'
        edit_stone_btn.style.visibility = 'visible'
        setStone(this_stone)
        name_input.placeholder = this_stone.name
        price_input.placeholder = this_stone.price
        cost_input.placeholder = this_stone.cost
        carrats_input.placeholder = this_stone.carrats
    }
    if(logged_in === false){
        return(
            <div>
                <p>You need to login first! This page is for admin use only!</p>
            </div>
        )
    }
    else if(stones===null)return<></>
    else{
        return(
            <div>
                <div>
                    <Link className='overview-link' to='/admin_management'>Admin Overview Page</Link>
                </div>
                <div className='stone-input'>
                    <input className='name-input' type='text' id='stone_name' placeholder='Stone Type' maxLength={255}/>
                    <input className='price-input' type='text' id='stone_price' maxLength={7}placeholder='Price'/>
                    <input className='cost-input' type='text' id='stone_cost' maxLength={7} placeholder='Stone Cost'/>
                    <input className='carrats-input' type='text' id='stone_carrats' maxLength={5} placeholder='Stone Weight in Carrats'/>
                    <input className='image-input' type='file' id='stone_image' placeholder='Image' alt="Image (.jpeg)" accept="image/jpeg" onChange={(event) => get_file_text(event)}/>
                </div>
                <div className='btns'>
                    <button id='add-stone' onClick={()=>add_stone(max_id,text)} className='add-stone-btn'>Add Stone</button>
                    <button id='edit-stone' onClick={()=>save_edits()} className='edit-stone-btn'>Save Edits</button>
                </div> 
                <div id="stone_choice" className="stone-choice">
                    {stones.map((stone,i)=>{
                        stone = JSON.parse(stone)
                        if( document.getElementById('so'+String(stone.id))===null){
                        return(
                                <div className='item' key={stone.id}>
                                    <div className='stone-item'>
                                        <StoneOption id={'so'+String(stone.id)} stone={stone} key={stone.id}/>
                                    </div>
                                    <div className='delete'>
                                        <button className='delete-btn' onClick={()=>delete_stone(stone.id)}>
                                            X
                                        </button>
                                    </div>
                                    <div className = 'edit'>
                                        <button className='edit-btn' onClick={() =>edit_stone(stone)}>
                                            <img className='edit-icon' src={edit_icon} alt=''/>
                                        </button>
                                    </div>
                                </div>
                        )}
                        else{
                            return <></>
                        }
                    })}
                </div>
            </div>
        )
    }
}

export default ManageStones 