import React from 'react'
import { logged_in } from './Login.jsx'
import { Link } from 'react-router-dom'
import './CSS/AdminManagement.css'

const AdminManagement=() => {
    let sales = 225
    if(logged_in === true){
        return(
            <div>
            <div className='management-links'>
                <Link className='stones-link' to='/admin_manage_stones'>Manage Stones</Link>
                <Link className='bands-link' to='/admin_manage_bands'>Manage Bands</Link>
                <Link className='rings-link' to='/admin_manage_rings'>Manage Rings</Link>
            </div>
            <div>
                <span>
                    <p className='total-sales'>Total Sales:</p> 
                    ${sales}
                </span>
            
            </div>
            </div>
        )
    }
    else{
        return(
            <div>
                <p>You need to login first! This page is for admin use only!</p>
            </div>
        )
    }
}

export default AdminManagement 