import React from "react";
import insta_icon from '../Components/Assets/instagram_icon.png'
import './CSS/Contact.css'
import { Link } from "react-router-dom";

const Contact = () => {
    return(
        <div className='contact-page'>
            <p className="email">Email: boojewelrystudio@gmail.com</p>
            <Link to="https://www.instagram.com/" className="insta"><img src={insta_icon} alt=""/></Link>
        </div>
    )
}

export default Contact