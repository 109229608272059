import React from "react";
import './BandOption.css'
import { print_band } from "../../Pages/CustomBand";

const BandOption = (props) => {
    const {band} = props
    if(band!==null){
       return(
            <div id={'div-'+String(band.id)} className="div-band">
                <button onClick={()=>print_band(band)} id={'btn-'+String(band.id)} className="btn-band">
                    <img src={'data:image/jpg;base64,'+band.image} id={'img-'+String(band.id)} alt="" className="img-stone"/>
                </button>
                <span className="band-info"><p>{band.name}</p> ${band.price}</span>
            </div>
       ) 
    }
}
export default BandOption