import React from "react";
import artist_picture from '../Components/Assets/home_photo3.jpeg'
import './CSS/About.css'

const About = () => {
    return(
        <div className="about-boo">
            <img className='about-img' src={artist_picture} alt=""/>
            <p className='about-text'>
                Boo jewelry is run by Kylie Roberts and Brandon Todd. All jewelry is handmade by Kylie Roberts.
                Kylie took inspiration from the native american jewelry style with her own spin.
                All stones are sourced from small businesses across the country. All pieces are in real 925 silver.    
            </p>
        </div>
    )
}

export default About