import React, { useEffect, useState }  from 'react'
import CartItem from '../Components/Item/Item'
import "./CSS/Cart.css"
import { Link } from 'react-router-dom'

let items_in_cart = []
let tax = 0;
function set_num_cart_items(){
    let cart_num = document.getElementById("nav-cart-count")
    cart_num.innerHTML = items_in_cart.length
}

export const add_to_cart = (product) =>{
    for(let i of items_in_cart){
        if(product.id === i.id && Object.keys(product).length === Object.keys(i).length){
            return;
        }
    }
    items_in_cart.push(product)
    set_num_cart_items()
}

export function get_total_price() {
    let total = 0
    for(let item of items_in_cart){
        total +=item.price
    }
    tax = total * 0.1025
    tax = Number(tax).toFixed(2)
    total = Number(total)+Number(tax)
    total = Number(total).toFixed(2)
    return total;
}

export const get_items_in_cart = () =>{
    return items_in_cart;
}

export const get_cart_total = () =>{
    return items_in_cart.length;
}

function count_of_item(id){
    let count = 0
    for( const i of items_in_cart){
        if(i.id === id){
            count++
        }
    }
    return count;
}

const Cart=() => {
    const [total_price,setTotalPrice] = useState(0)
    useEffect(() =>{
        setTotalPrice(get_total_price())
    },[total_price]);
    let id_lst = []

    function decrement_item(item){
        const index = items_in_cart.indexOf(item)
        if(index>-1){
            items_in_cart.splice(index,1)
            set_num_cart_items()
            let item_cnt = count_of_item(item.id)
            if(item_cnt>0){
                let item_num = document.getElementById("item_cnt"+String(item.id))
                item_num.innerHTML = item_cnt
            }
            else{
                let item_cnt = document.getElementById("items-in-cart")
                if(item_cnt){
                    let child_item = document.getElementById("item_cnt"+String(item.id))
                    if(child_item){
                        item_cnt.removeChild(child_item)
                    }
                }
            }
            let price = get_total_price()
            tax = total_price*0.1025 
            tax = Number(tax).toFixed(2)
            price = price + tax
            price = Number(price).toFixed(2)
            setTotalPrice(price);
        }
    }
    return(
        <div className='cart'>
            <div className= "items-in-cart">
                {items_in_cart.map((item,i)=>{
                    if(!id_lst.includes(item.id, 0)){
                        id_lst.push(item.id)
                        const item_cnt = count_of_item(item.id)
                        return (
                            <div id={"item_div"+String(item.id)} key={item.id} className='row'>
                                <CartItem id={item.id} name={item.name} image={item.image} price={item.price}/>
                                <div className='count'>
                                    <div className='count-text'>
                                        Count: <span id={"item_cnt"+String(item.id)}>{item_cnt}</span>
                                    </div>
                                    <div className="inc-dec-buttons">
                                        <button className="decrement" onClick={()=>decrement_item(item)}>Remove</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    else{
                        return(<></>)
                    }
                })}
            </div>
            <br/>
            <b className='tax-tag'>Sales Tax:
                <span className='tax'> ${tax}</span>
            </b>
            <div className='bottom-row'>
                <div className="total-price">
                    <b className='price-tag'>Total Price: </b>
                    <span className="price">$<span id="cart_price">{total_price}</span></span>
                </div>
                <div className='checkout-cart-btn'>
                    <Link to="/checkout"><form method="POST"><button type='submit' className='checkout-cart-button'>Checkout</button></form></Link>
                </div>
            </div>
        </div>
    )
}
export default Cart; 