import React, {useState, useEffect} from 'react'
import { logged_in } from './Login.jsx'
import { Link } from 'react-router-dom'
import './CSS/ManageBands.css'
import BandOption from "../Components/BandOption/BandOption";
import edit_icon from '../Components/Assets/edit_pencil_icon.jpg'

export const get_max_id = (items) =>{
    let id = 50000
    if(items===null)return 50000
    for( let i of items){
        i = JSON.parse(i)
        if(i.id >id){
            id = i.id
        }
    }
    return id
}

const ManageBands =() => {
    const [bands, setBands] = useState(null)
    const [text, setText] = useState(null)
    const [band, setBand] = useState(null)
    useEffect(()=> {
        fetch(process.env.REACT_APP_API_URL+'/all_bands?')
            .then(response => {
                response.json().then((data) => {
                    if(bands === null)
                        setBands(data)
                })
            })
            .catch(error=>{
                console.error(error);
            })
    }, [bands]);
    const get_file_text = async (event) => {
        let file = document.getElementById('band_img').files[0]
        event.preventDefault()
        if(file){
            var reader = new FileReader();
            reader.onload = async (event) => {
                setText(event.target.result.split(',')[1]);
            }
            reader.onerror = function(event){
                window.alert("Error Reading File")
            }
            reader.readAsDataURL(file)
            }
    }
    let max_id = get_max_id(bands)
    const add_band = async (max_id) => {
        let band_name = document.getElementById('band_name').value
        let band_img = null
        band_img =  text;
        if(band_name===null){
            window.alert('Invalid input to band name');
            return
        }
        let band_price = document.getElementById('band_price').value
        if(band_price===null){
            window.alert('Invalid input to band price');
            return
        }
        if(band_img===null){
            window.alert('Invalid input to band image file');
            return
        }
        let cost_input = document.getElementById('band_cost').value
        if(cost_input === null){
            window.alert('Invalid input to band cost');
            return 
        }
        const response = await fetch(process.env.REACT_APP_API_URL+"/insert_band",{
            method:["POST"],
            headers: {"Content-Type":"application/json"},
            body: JSON.stringify({
                name: band_name,
                price: band_price,
                image: band_img,
                cost: cost_input,
                id: max_id+1,
            }),
        })
        const data = await response.text()
        if(data !== 'ok'){
            window.alert(data)
        }
        setBands(null)
        return
    }
    const save_edits = async () =>{
        let add_band_btn = document.getElementById('add-band') 
        let edit_band_btn = document.getElementById('edit-band')
        let band_name = document.getElementById('band_name').value
        if(band_name===null){
            window.alert('Invalid input to band name');
            return
        }
        let band_price = document.getElementById('band_price').value
        if(band_price===null){
            window.alert('Invalid input to band price');
            return
        }
        let cost_input = document.getElementById('band_cost').value
        if(cost_input === null){
            window.alert('Invalid input to band cost');
            return 
        }
        const response = await fetch(process.env.REACT_APP_API_URL+"/edit_band",{
            method:["POST"],
            headers: {"Content-Type":"application/json"},
            body: JSON.stringify({
                name: band_name,
                price: band_price,
                cost: cost_input,
                id: band.id,
            }),
        })
        const data = await response.text()
        if(data !== 'ok'){
            window.alert(data)
        }
        add_band_btn.style.visibility = 'visible'
        edit_band_btn.style.visibility = 'collapse'
        setBands(null)
        setBand(null)
        return
    }
    const delete_band = async (stone_id) =>{
        const response = await fetch(process.env.REACT_APP_API_URL+"/delete_band",{
            method:["POST"],
            headers: {"Content-Type":"application/json"},
            body: JSON.stringify({
                id: stone_id,
            }),
        })
        const data = await response.text()
        if(data !== 'ok'){
            window.alert(data)
        }
        setBands(null)
        return
    }
    const edit_band = (this_band) =>{
        let add_band_btn = document.getElementById('add-band') 
        let edit_band_btn = document.getElementById('edit-band')
        let name_input = document.getElementById('band_name')
        let price_input = document.getElementById('band_price')
        let cost_input = document.getElementById('band_cost')
        add_band_btn.style.visibility = 'collapse'
        edit_band_btn.style.visibility = 'visible'
        setBand(this_band)
        name_input.placeholder = this_band.name
        price_input.placeholder = this_band.price
        cost_input.placeholder = this_band.cost
    }
    if(logged_in === false){
        return(
            <div>
                <p>You need to login first! This page is for admin use only!</p>
            </div>
        )
    }
    else if(bands===null)return<></>
    else{
        return(
            <div>
                <div>
                    <Link className='overview-link' to='/admin_management'>Admin Overview Page</Link>
                </div>
                <div className='band-input'>
                    <input className='name-input' type='text' placeholder='Band Type' maxLength={255} id='band_name'/>
                    <input className='price-input' type='text' placeholder='Price' maxLength={7} id='band_price'/>
                    <input className='cost-input' type='text' placeholder = 'Cost' maxLength={7} id = 'band_cost'/>
                    <input className='image-input' type='file' placeholder='Image (jpg)' id='band_img' accept="image/jpeg" onChange={(event) => get_file_text(event)}/>
                </div>
                <div className='btns'>
                    <button id='add-band' onClick={()=>add_band(max_id)} className='add-band-btn'>Add Band</button>
                    <button id='edit-band' onClick={()=>save_edits()} className='edit-band-btn'>Save Edits</button>
                </div>
                <div id='band_choice' className="band-choice">
                   {bands.map((band,i)=>{
                       band = JSON.parse(band)
                        if( document.getElementById('bo'+String(band.id))===null){
                            return(
                                <div className='item' key={band.id}>
                                    <div className='band-item'>
                                        <BandOption id={'bo'+String(band.id)} band={band} key={band.id}/>
                                    </div>
                                    <div className='delete'>
                                        <button className='delete-btn' onClick={()=>delete_band(band.id)}>
                                            X
                                        </button>
                                    </div>
                                    <div className = 'edit'>
                                        <button className='edit-btn' onClick={() =>edit_band(band)}>
                                            <img className='edit-icon' src={edit_icon} alt=''/>
                                        </button>
                                    </div>
                                </div>
                        )}
                        else{
                            return <></>
                        }
                    })
                    }
                </div>
            </div>
        )
    }
}

export default ManageBands